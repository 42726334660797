import { render, staticRenderFns } from "./_deliverySpbCourier.vue?vue&type=template&id=5c962c18&"
import script from "./_deliverySpbCourier.vue?vue&type=script&lang=js&"
export * from "./_deliverySpbCourier.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../usr/local/lib/node_modules/@vue/cli-service-global/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports