<template>
    <div>
        
        <div class="alert alert-danger font-s16 font-lh140" v-if="showErrors">
            <span class="font-w500">Необходимо заполнить:</span>
            {{ $store.getters.validationResultString }}
        </div>
        
        <div class="bottom-50">
            
            <button
                v-if="!$store.state.basketStore.isLoading && $store.getters.validation.length <= 0"
                @click="checkout()"
                class="basket__checkoutButton">{{ text }}
            </button>

            <button
                v-if="!$store.state.basketStore.isLoading && $store.getters.validation.length > 0"
                class="basket__checkoutButton disabled">{{ text }}
            </button>
            
            
            <button
                v-if="$store.state.basketStore.isLoading"
                class="basket__checkoutButton disabled">Сохраняем..
            </button>
        
        </div>
    
    </div>
</template>

<script>
    module.exports = {
        name: "basketValidation",
        data() {
            return {
                showErrors: false
            }
        },
        computed: {
            order() {
                return this.$store.getters.order;
            }, 
            text () {
                return this.order.payment_type_id === 2 ?
                    'Перейти к оплате' :
                    'Оформить заказ';
            }

        },
        methods: {
            checkout() {
                if (this.$store.getters.validation.length > 0) {
                    this.showErrors = true;
                } else {
                    this.$store.dispatch("doneOrder");
                }
            }
        }
    };
</script>
