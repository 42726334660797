<template>
    <div v-if="save_adr === false">
        <div class="basket__formItem">
            <div class="basket__formLabel">
                Город доставки <span :class="['basket_required', {'basket_required_empty':(!order.city_id)}]">*</span>
            </div>
            <div class="basket__formContainer">
                <input type="text"
                       id="delivery_city_target"
                       v-model="order.city_name"
                       placeholder="Начните вводить для поиска"
                       class="basket__formField autocomplete">
                <div v-if="order.delivery_price || order.delivery_days" class="top-15 font-s14 text-muted">
                    <p>
                        Цена: {{ order.delivery_price }} руб.
                        <br>
                        Ориентировочный срок доставки: {{ order.delivery_days | numString('день|дня|дней') }}
                    </p>
                </div>
            </div>
        </div>

        <div class="basket__formItem">
            <div class="basket__formLabel">
                Адрес доставки <span :class="['basket_required', {'basket_required_empty':(!order.adr || order.adr.length < 5)}]">*</span>
            </div>
            <div class="basket__formContainer">
                <textarea rows="8"
                          class="basket__formField textfield fullwidth"
                          @keyup="$store.dispatch('updateOrder', order)"
                          v-model="order.adr">
                </textarea>
                <div class="basket__formHelp">
                    Улица, дом, корпус, квартира/офис
                </div>
            </div>
        </div>
        <div class="top-10">
            <button 
                    v-if="(this.order.city_id) && (this.order.adr.trim().length > 5)"
                    class="btn btn-success"
                    type="button"
                    @click="adr_toogle()" 
                >Подтвердить</button>
                <button 
                    v-else
                    class="btn "
                    type="button" 
                >Подтвердить</button>
        </div>

    </div>
     <div v-else>
        <div class="basket__deliveryTitle">
            Адрес доставки:
        </div>
        <div class="basket__deliveryInfo" >
            <div class="basket__deliveryName">
                {{ order.adr }}
            </div>
        </div>
        <div class="basket__deliveryTitle">
            Город доставки:
        </div>
        <div class="basket__deliveryInfo">
            <div class="basket__deliveryName">
                {{ order.city_name }}
            </div>
        </div>
         <a
            @click="adr_toogle()"
            role="button"
            class="basket__change">Изменить
        </a>
    </div>
</template>

<script>
    module.exports = {
        name: 'basketDeliveryRussiaCourier',
        data: function() {
            return {
                save_adr: false,
            }
        },
        computed: {
            order() {
                return this.$store.getters.order
            },
            sourceDelivery() {
                return new Bloodhound({
                    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url: '/services/search_city/%QUERY',
                        wildcard: '%QUERY',
                        filter: function (matches) { return matches }
                    }
                })
            }
        },
        mounted() {
            this.initTypeahead()
        },
        methods: {
            initTypeahead() {
                $('#delivery_city_target').typeahead( null, {
                    name: 'source-delivery',
                    display: (item) => {
                        return `${item.name}`
                    },
                    source: this.sourceDelivery
                }).on('typeahead:selected', (event, payload) => {
                    this.setDeliveryCity(payload)
                })
            },
             adr_toogle() {
                this.save_adr = !this.save_adr
            },
            setDeliveryCity(payload) {
                this.order.city_id = payload.id
                this.order.city_name = payload.name
                this.$store.dispatch('updateOrder', this.order)
                this.calculateDeliveryPrice()
            },
            calculateDeliveryPrice() {
                axios.get(`/services/delivery_price/${this.order.city_id}`).then(response => {
                        this.order.delivery_price = (this.order.city_id == 137)
                            ? 300
                            : +response.data.payload.price
                        this.order.delivery_days = +response.data.payload.deliveryPeriodMin + 1
                        this.$store.dispatch('updateOrder', this.order)
                    })
            }
        }
    }
</script>
