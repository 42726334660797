<template>
    <div class="col-xs-12">
        <div class="panel">
            <div class="panel-body">
                <div class="h1">Спасибо, ваш заказ оформлен!</div>
                <div class="font-s16">
                    <p>На номер {{ $store.getters.order.phone }} мы отправили СМС-подтверждение заказа.</p>
                    <p v-if="$store.getters.order.email">
                        На почту {{ $store.getters.order.email }} отправлена детальная информация по вашему заказу.
                    </p>
                    <p v-if="$store.getters.order.payment_type_id===2">
                        Вы будетете переадресованы на страницу оплаты.
                        Если этого не произошло, пожалуйста перейдите по ссылке: <a v-bind:href="'/payment/' + paymentHash">Оплатить</a> 

                    </p>
                </div>
                 <div class="top-20" v-if="$store.getters.order.payment_type_id===2">
                    <a v-bind:href="'/payment/' + paymentHash" class="btn btn-primary">Перейти к оплате</a>
                </div>

                <div class="top-20">
                    <a href="/" class="btn btn-primary">Продолжить покупки</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    module.exports = {
        name: 'basketDone',
        data() {
            return {
                //
            }
        },
        computed: {
            paymentHash: {
                get() {
                    console.log(this.$store.getters.paymentHash)
                    return this.$store.getters.paymentHash;
                }
            },
        },
        mounted() {
            if (this.$store.getters.order.payment_type_id === 2) {
                setTimeout(() => {
                    window.location = '/payment/' + this.paymentHash;
                }, 3000)

            }
            
        }
    }
</script>
