<template>
    <div v-if="save_adr === false">
        <div class="basket__formItem">
            <div class="basket__formLabel">
                Адрес доставки <span :class="['basket_required', {'basket_required_empty':(!order.adr || order.adr.length < 5)}]">*</span>
            </div>
            <div class="basket__formContainer">
                <textarea rows="8"
                          class="basket__formField textfield fullwidth"
                          @keyup="$store.dispatch('updateOrder', order)"
                          v-model="order.adr">
                </textarea>
                <div class="basket__formHelp">
                    Улица, дом, корпус, квартира/офис
                </div>
            </div>
        </div>

        <div v-show="order.delivery===3" class="basket__formItem">
            <div class="basket__formLabel">Желаемая дата получения
                <span :class="['basket_required', {'basket_required_empty':(!order.date_of_delivery)}]">*</span>
            </div>
            <input type="text" id="date_of_delivery" class="basket__formField expanded" v-model="orderDate">
        </div>

        <div class="top-10">
                <button 
                    v-if="(this.orderDate.trim() || this.order.delivery!==3 ) && (this.order.adr.trim().length > 5)"
                    class="btn btn-success"
                    type="button"
                    @click="adr_toogle()" 
                >Подтвердить</button>
                <button 
                    v-else
                    class="btn "
                    type="button" 
                >Подтвердить</button>
                
        </div>

    </div>
    <div v-else >
    	<div class="basket__deliveryTitle">
	        Адрес:
	    </div>
    	<div class="basket__deliveryInfo" >
            <div class="basket__deliveryName">
                {{ order.adr }}
            </div>
        </div>
        <div class="basket__deliveryTitle" v-show="order.delivery===3" >
	        Дата:
	    </div>
    	<div class="basket__deliveryInfo" v-show="order.delivery===3" >
            <div class="basket__deliveryName">
                {{ orderDate }}
            </div>
        </div>
         <a
            @click="adr_toogle()"
            role="button"
            class="basket__change">Изменить
        </a>
    </div>
</template>

<script>
    module.exports = {
        name: 'basketDeliverySpbCourier',
        data: function() {
        	return {
        		save_adr: false,
        	}
        },
        computed: {
            order: {
                get() { return this.$store.getters.order },
                set() { this.$store.dispatch('updateOrder', this.order) }
            },
            orderDate: {
                get() {
                    if (this.order.date_of_delivery) {
                        return moment(this.order.date_of_delivery).format("DD.MM.YYYY")
                    } else {
                       //return moment(this.getFirstAvailableDate.toDate()).format("DD.MM.YYYY")
                       return '';
                    }
                },
                set(val) {
                    this.order.date_of_delivery = moment(val).format("YYYY-MM-DD")
                    if(this.order.date_of_delivery === 'Invalid date') {
                        this.order.date_of_delivery = '';
                    }
                    this.$store.dispatch('updateOrder', this.order)
                }
            },
            now() {
                return moment(new Date).clone()
            },
            getFirstAvailableDate() {
                let response
                // switch (this.now.day()) {
                //     case 0: // воскресенье
                //         response = this.now.hour() > 17 ? this.now.add(1, 'd') : this.now.add(1, 'd')
                //         break;
                //     case 1: // понедельник
                //         response = this.now.hour() > 17 ? this.now.add(1, 'd') : this.now.add(1, 'd')
                //         break;
                //     case 2: // вторник
                //         response = this.now.hour() > 17 ? this.now.add(1, 'd') : this.now.add(1, 'd')
                //         break;
                //     case 3: // среда
                //         response = this.now.hour() > 17 ? this.now.add(1, 'd') : this.now.add(1, 'd')
                //         break;
                //     case 4: // четверг
                //         response = this.now.hour() > 17 ? this.now.add(1, 'd') : this.now.add(1, 'd')
                //         break;
                //     case 5: // пятница
                //         response = this.now.hour() > 17 ? this.now.add(1, 'd') : this.now.add(1, 'd')
                //         break;
                //     case 6: // суббота
                //         response = this.now.hour() > 17 ? this.now.add(1, 'd') : this.now.add(1, 'd')
                //         break;
                // }
                return this.now.add(1, 'd');
            },
        },
        methods: {
            isNotAvailable(date) {
                return false
                //return [6, 0].indexOf(moment(date).day()) !== -1
            },
            initDatepicker() {

                    let deliveryPicker = $('input#date_of_delivery').datepicker({
                        minDate: this.getFirstAvailableDate.toDate(),
                        dateFormat: "yy-mm-dd",
                        autoClose: true,
                        position: "bottom center",
                        toggleSelected: false,
                        onRenderCell: (date, cellType) => {
                            if (cellType === 'day') {
                                return {
                                    disabled: this.isNotAvailable(date)
                                }
                            }
                        },
                        onSelect: (formattedDate, date) => { 
                            this.orderDate = formattedDate 
                        }
                    })
                    // .data('datepicker')
                    //.selectDate(this.orderDate ? this.orderDate :  moment(this.getFirstAvailableDate.toDate()).format("DD.MM.YYYY"));
            },
            adr_toogle() {
                if (!this.save_adr && (this.orderDate.trim() || this.order.delivery!==3) && (this.order.adr.trim().length > 5)) {
                    this.save_adr = !this.save_adr;
                } else {
                    this.save_adr = !this.save_adr;
                }
	
            }
        },
        mounted() {
            if (!this.order.time_intervals) {
                this.order.time_intervals = "Доставка до 16:00"
            }
            this.initDatepicker()
        },
        updated() {
            if (this.save_adr === false){
                this.initDatepicker();
                console.log('updated');
            }
        }
    }
</script>
