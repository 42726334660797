<template>
    <div v-if="save_adr === false">
        <div class="basket__formItem">
            <div class="basket__formLabel">
                Почтовый индекс <span :class="['basket_required', {'basket_required_empty':(!order.post_index)}]">*</span>
            </div>
            <input type="text"
                   id="basket_delivery_post_index"
                   class="basket__formField"
                   @change="$store.dispatch('updateOrder', order)"
                   v-model="order.post_index">
        </div>

        <div class="basket__formItem">
            <div class="basket__formLabel">
                Адрес доставки <span :class="['basket_required', {'basket_required_empty':(!order.adr || order.adr.length < 5)}]">*</span>
            </div>
            <div class="basket__formContainer">
                <textarea rows="8"
                       class="basket__formField textfield fullwidth"
                       @keyup="$store.dispatch('updateOrder', order)"
                       v-model="order.adr">
                </textarea>
                <div class="basket__formHelp">
                    Область, город, улица, дом, корпус, квартира/офис
                </div>
            </div>
        </div>
        <div class="top-10">

            <button 
                    v-if="(this.order.post_index) && (this.order.adr.trim().length > 5)"
                    class="btn btn-success"
                    type="button"
                    @click="adr_toogle()" 
                >Подтвердить</button>
                <button 
                    v-else
                    class="btn "
                    type="button" 
                >Подтвердить</button>
        </div>

    </div>
    <div v-else>
        <div class="basket__deliveryTitle">
            Адрес доставки:
        </div>
        <div class="basket__deliveryInfo" >
            <div class="basket__deliveryName">
                {{ order.adr }}
            </div>
        </div>
        <div class="basket__deliveryTitle">
            Почтовый индекс:
        </div>
        <div class="basket__deliveryInfo">
            <div class="basket__deliveryName">
                {{ order.post_index }}
            </div>
        </div>
        <a
            @click="adr_toogle()"
            role="button"
            class="basket__change">Изменить
        </a>
    </div>
</template>

<script>
    module.exports = {
        name: 'basketDeliveryRussianPost',
         data: function() {
            return {
                save_adr: false,
            }
        },
        computed: {
            order() {
                return this.$store.getters.order
            },
        },
         methods: {
             adr_toogle() {
                this.save_adr = !this.save_adr
            }
        },
        created() {
            let self = this
            $script.ready('maskedinput', function() {
                $('#basket_delivery_post_index').mask( "999999", {
                    autoclear: false,
                    completed: function() {
                        self.order.post_index = this.val()
                        self.$store.dispatch('updateOrder', self.order)
                    }
                })
            })
        }
    }
</script>
