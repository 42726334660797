window._ym = {
    goal(goal) {
        if (typeof yaCounter23274694 !== "undefined") {
            yaCounter23274694.reachGoal(goal)
        }
    },
    params(payload) {
        if (typeof yaCounter23274694 !== "undefined") {
            yaCounter23274694.params(payload)
        }
    }
};
